import Header from '../Header';

import { HeadingLevel, Heading } from '@ariakit/react';

const Contact = () => (
    <div>
        <HeadingLevel>
            <Header text="contact" />
            <div className="main-content">
                <HeadingLevel>
                    <Heading>Want to hire me or collab?</Heading>
                    <p>
                        Are you looking for a passionate game developer or programmer to join your team or collaborate on an exciting project? 
                    </p>
                    <p>
                        I'd love to hear from you! Feel free to reach out to me via&nbsp;
                        <a title="My email" target="_blank" rel="noreferrer" href="mailto:howdy@noahkuhn.website">email</a>
                        &nbsp;or <a title="My LinkedIn" target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/noah-kuhn/">LinkedIn</a>.
                        You can also grab a copy of&nbsp;
                        <a title="My resume on Google Docs" target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/1Wd7s_Ls6pxHOaJrqL5CvBYCwY7UKax6U/view?usp=sharing">
                            my resume
                        </a>, if you'd like.
                    </p>
                    <p>Thank you for your interest in my work, and I look forward to connecting with you soon.</p>
                </HeadingLevel>
                <HeadingLevel>
                    <Heading>Just want to keep up with me and my projects?</Heading>
                    <p>
                        If you're more interested in staying updated on my personal projects or connecting with me on social media,&nbsp;
                        <a title="My linktr.ee" target="_blank" rel="noreferrer" href="https://linktr.ee/noahkuhn">my linktree</a> may be what you need.
                        In addition to the professional links both in the above section and in the footer, it contains links to platforms on which I post
                        the stuff I make. 
                    </p>
                </HeadingLevel>
            </div>
        </HeadingLevel>
    </div>
);

export default Contact;