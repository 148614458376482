import Header from '../Header';
import NoahKuhnHeadshot from '../../assets/images/NoahKuhn_Headshot_sq.jpg';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGears } from '@fortawesome/free-solid-svg-icons';
import { HeadingLevel, Heading } from '@ariakit/react';

const Homepage = () => (
    <div>
        <HeadingLevel>
            <Header text="howdy!" />
            <div className="main-content">
                <HeadingLevel>
                    <Heading>
                        <span className="montserrat">NOAH KUHN </span>is a programmer, game developer, and
                        <br />
                        general maker&#8209;of&#8209;things based in Seattle, Washington.
                    </Heading>
                    <p>
                        With experience ranging from <a title="my portfolio colletion on itch.io" target="_blank" rel="noreferrer" href="https://itch.io/c/2947077/portfolio">indie games</a> to <a title="Meta Website" target="_blank" rel="noreferrer" href="https://about.meta.com">Meta</a>, Noah Kuhn is ready and eager to make cool things of any scope with you.
                    </p>
                    <div className="image-paragraph-container">
                        <img className="big-image" src={NoahKuhnHeadshot} alt="Headshot: that's my face!" />
                        <p>
                            Noah graduated with honors from the University of Texas at Austin, earning a Bachelor of Science in Computer Science. Additionally, they
                            earned a 19-hour certificate in Digital Arts and Media with an emphasis in Game Development. In short, they studied making computers do
                            cool things&hellip; a lot.
                        </p>
                        <p>
                            Their enthusiasm for programming games naturally led them to be an active member of UT Austin's Electronic Game Developers Society
                            (<a title="EGaDS! Website" target="_blank" rel="noreferrer" href="https://texasegads.wixsite.com/egads/about">EGaDS!</a>),
                            serving as its Main Events Officer in 2021-22 and its President in 2022-23. In these leadership positions, they worked with a dynamic
                            team to meet the organization's high-level objectives while also cultivating a thriving community of hundreds of student game developers.
                        </p>
                        <p>
                            Noah's diverse <Link to="/history" title="My work history">professional experience</Link> highlights their flexibility: they've worked
                            both in software and in games, in enormous code bases and in teams as small as three. In their current role at Meta, they collaborate with other
                            engineers, product managers, designers, and more to provide solutions for both user-facing features and internal tools only used by other Meta
                            developers. Working with smaller teams, they've taken on responsibilities such as designing key systems and architecting class hierarchies to
                            ensure project extensibility.
                        </p>
                    </div>
                    <HeadingLevel>
                        <Heading className="montserrat"><FontAwesomeIcon icon={faGears} /> SKILLS</Heading>
                        <p>
                            <span className="montserrat">LANGUAGES: </span>In their current role, Noah mostly
                            uses <a title="Hack Website" target="_blank" rel="noreferrer" href="https://hacklang.org/">Hack</a>, <a title="GraphQL Website" target="_blank" rel="noreferrer" href="https://graphql.org/">GraphQL</a>,
                            JavaScript, SQL, and Python.
                            <br />
                            They also have extensive experience with C#, C++, C, Java, and TypeScript, as well as some familiarity with Lua, HTML, and
                            CSS + <a title="Sass Website" target="_blank" rel="noreferrer" href="https://sass-lang.com/">Sass</a>.
                        </p>
                        <p>
                            <span className="montserrat">TOOLS: </span>Noah has professional experience
                            with <a title="React Website" target="_blank" rel="noreferrer" href="https://react.dev/">React</a> and <a title="Relay Website" target="_blank" rel="noreferrer" href="https://relay.dev/">Relay</a>.
                            <br />
                            When it comes to game development, Noah has over six years of experience working in Unity. They have also worked in Unreal and Godot.
                            <br />
                            For source control, Noah has extensive experience
                            with <a title="Mercurial Website" target="_blank" rel="noreferrer" href="https://www.mercurial-scm.org/">Mercurial</a> and Git, as well as some
                            familiarity with Plastic SCM.
                        </p>
                        <p>
                            <span className="montserrat">PRACTICES: </span>Noah has experience with code review, agile development via scrum, continuous integration,
                            and rapid prototyping + iteration.
                        </p>
                    </HeadingLevel>
                </HeadingLevel>
            </div>
        </HeadingLevel>
    </div>
);

export default Homepage;